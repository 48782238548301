/*
* @Author: liuzhipeng
* @Date: 2021-07-12 14:12:54
* @Last Modified by: liuzhipeng
* @Last Modified time: 2021-07-16 15:08:31
*/
<template>
  <div>
    <div class="Section">
      <!-- 第一行 -->
      <div class="breadcrumbNav">
        <span class="navName">当前位置 : </span>
        <div class="activeNav">
          <span @click="FirstClickFun()">{{ clickVal }} </span>
        </div>
      </div>
      <!-- 二级菜单 -->
      <!-- <div class="sortBox">
        <div class="sortUlbox">
          <ul class="sortUl">
            <li v-for="(item, index) in lists" :key="index" @click="listsAdd(item, index)"
              :class="{ active: Boolean(checkFirst === index) }">
              {{ item.categoryName }}
            </li>
          </ul>
        </div>
      </div> -->
      <!-- 筛选 -->
      <!-- <ul class="filterUl">
        <li v-for="(item, index) in filterItems" :key="index" @click="filterFun(item,index)"
          :class="{ active: Boolean(filterType === index) }">
          {{ item.sort }}
          <i :class="
              item.filterMark == 'price' ? Boolean(item.show === 1)?'active el-icon-caret-top  iconTop':'el-icon-caret-top  iconTop':Boolean(filterType === index)?'active el-icon-bottom':'el-icon-bottom'
            " aria-hidden="true"></i>
          <i v-if="item.filterMark == 'price'"
            :class="Boolean(item.show === 2)?'active el-icon-caret-bottom iconBottom':'el-icon-caret-bottom iconBottom'"
            aria-hidden="true"></i>
        </li>
        <li>
          <el-checkbox v-model="checked" @change="filterFun()">仅显示有货</el-checkbox>
        </li>
      </ul> -->
      <!-- 商品 -->
      <div class="goodsList" v-if="searchProducts.length>0">
        <ItemComboLi :productType="clickID" v-for="(item,index) in searchProducts" :key="index" class="goods"
          :info="item"></ItemComboLi>
      </div>
      <!-- 分页 -->
      <!-- <div class="pageBox">
        <el-pagination background layout="prev, pager, next" :hide-on-single-page="false" prev-text="上一页"
          next-text="下一页" @current-change="handleCurrentChange" :current-page="current" :page-size="size"
          :total="total">
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
  const ItemComboLi = () => import('components/index/ItemComboLi.vue');
  import {
    getNoPostageOfSome,
  } from 'api/activity.js';

  export default {
    name: "FreeMail",
    inject: ['reload'],
    data() {
      return {
        clickVal: '', //  面包屑 初始，一级分类选中值为空
        clickID: '', //一级分类ID 
        searchProducts: '', //初始商品数据
      };
    },
    created() {
      this.clickVal = '小额包邮';
      this.clickID = 'FreeMailGoods'

      this.fetchData()
    },
    methods: {
      //获取小额包邮列表
      async fetchData() {
        const datas = {}
        const result = await getNoPostageOfSome(datas)
        console.log('小额包邮列表: ', result);
        if (result.code === 200) {
          this.searchProducts = result.data
        } else {
          this.searchProducts = []
        }
      },
      // 点击当前位置一级分类
      FirstClickFun() {
        this.reload();
      }
    },
    watch: {},
    components: {
      ItemComboLi,
    },
    mounted() {},
    updated() {}
  };
</script>
<style>
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ed3129;
    color: #fff;
  }

  /* 上一页，下一页样式 */
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    background: #fff;
    color: #666;
    padding: 2px;
    box-sizing: border-box;
  }

  /* 每个页码的样式 */
  .el-pagination.is-background .el-pager li {
    background: #fff;
    color: #666;
    padding: px;
    box-sizing: border-box;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #ed3129;
    font-size: 14px;
  }
</style>
<style lang="less" scoped>
  .pageBox {
    float: left;
    width: 100%;

    .el-pagination {
      padding-top: 40px;
      text-align: center;
      float: right;
    }
  }

  @import "~style/index.less";

  /* 隐藏未编译的变量 */
  [v-cloak] {
    display: none;
  }

  .Section {
    width: 1200px;
    margin: 10px auto 0;
    background: #f6f6f6;
    padding-bottom: 40px;
    overflow: hidden;

    .breadcrumbNav {
      padding: 8px 15px 15px 0;
      margin-bottom: 17px;
      font-size: 13px;
      line-height: 20px;

      .navName {
        float: left;
        color: #999;
        padding-right: 8px;
      }

      .activeNav {
        float: left;
        color: #999;
        line-height: 20px;

        span {
          color: #551a8b;
          padding-right: 8px;
          float: left;
          cursor: pointer;
        }

        b {
          float: left;
        }

        .checkUl {
          float: left;
          padding-left: 8px;

          li {
            padding: 0 5px;
            height: 20px;
            line-height: 18px;
            border: 1px solid #ff9999;
            color: #ff9999;
            font-size: 12px;
            background: #fff;
            margin-right: 8px;
            float: left;
            cursor: pointer;
          }
        }
      }
    }

    .sortBox {
      box-sizing: content-box;
      background-color: #ffffff;
      // margin-bottom: 17px;
      border: 1px solid #eeeeee;
      border-bottom: none;
      width: 100%;
      float: left;

      .sortUlbox:last-child {
        border-bottom: none;
      }

      .sortUlbox {
        width: 100%;
        padding: 8px 15px;
        float: left;
        line-height: 40px;
        border-bottom: 1px dashed #ccc;

        .ulTitle {
          font-size: 14px;
          font-weight: bold;
          color: #666666;
          width: 136px;
          text-align: center;
          float: left;
        }

        .sortUl {
          width: calc(100% - 136px);
          float: left;

          li {
            float: left;
            margin-right: 40px;
            color: #999;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
              color: @theme;
            }
          }

          li.active {
            color: @theme;
          }
        }
      }
    }

    .filterUl {
      padding: 8px 15px;
      margin-bottom: 17px;
      border: 1px solid #eeeeee;
      box-sizing: content-box;
      background-color: #ffffff;
      height: 20px;
      line-height: 20px;
      width: 100%;
      float: left;

      li.active {
        color: #ff9999;
      }

      li {
        float: left;
        margin-right: 40px;
        color: #666666;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          color: @theme;

          .el-checkbox {
            color: @theme;
          }
        }

        i {
          font-weight: bolder;
          font-size: 14px;
        }
      }
    }

    .goodsList {
      height: auto;
      width: 100%;
      // overflow: hidden;
      float: left;
      min-height: 228px;
      background: #f6f6f6;

      .goods:nth-child(5n + 0) {
        margin-right: 0px;
      }
    }
  }
</style>